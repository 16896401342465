<template>
   <v-footer  padless>
    <v-card class="flex" flat tile>
      <v-card-title class="footer-top">
          <v-flex class="footer-inline" :class="is_screen_small ? 'xs12 sm12 offset-sm0' : ' xs9 sm9 offset-sm2' " >
             <v-avatar  height="40" width="141" min-width="141px" tile :class="{'mobile-view-width' :is_screen_small}" class="goHome" @click="goHome()">
              <v-img :src="`${s3Path}logoWhite.png`"></v-img>
            </v-avatar>
         <v-card class="mx-auto hidden-sm-and-down" max-width="300" dark flat  tile color="red">
            <v-list flat class="pa-0">
              <p class="ml-5 footer-header">Company</p>
              <v-card tile flat :color="`${zamuGrey}`" height="1" class="justify-left ml-4 transact-underline footer-underline">
                    <v-card-text class="footer-divider divider-purple"></v-card-text>
                </v-card>
              <v-list-item-group
                v-model="selectedItem"
                color="primary"
              >
                <v-list-item
                  v-for="(item, i) in items"
                  :key="i"
                >
                  <v-list-item-content @click="changeRoute(item)" >
                    <v-list-item-title class="footer-list-item" dark v-text="item.title" :class="{'footer-active-link': activeLink === item.text }"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        <v-btn v-for="(icon, index) in socialMedia" :href="icon.handle"  :key="index" class="mx-4 mt-14 hidden-sm-and-down
        " dark icon >
          <v-icon size="33px" color="#d29f2a">
            {{ icon.icon }}
          </v-icon>
        </v-btn>
          </v-flex>
      </v-card-title>

      <v-card-text class="py-2 white--text footer-bottom" >
          <v-flex xs9 sm9 offset-sm2 class="footer-inline">
             <strong><p class="copyright-text">Copyright ©  {{ new Date().getFullYear() }} </p></strong>
             <v-spacer></v-spacer>
            <v-btn  v-scroll="onScroll"  v-show="fab" min-width="50" width="50" height="50"   fixed  bottom left :color="`${zamuYellow3}`"  @click="toTop">
             <v-icon>mdi-chevron-up</v-icon>
           </v-btn>
         </v-flex>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
import {  mapGetters, mapMutations } from 'vuex';

import coreMixin from '@/mixins/coreMixin';
import colorMixin from '@/mixins/colorMixin';


export default {
    name:'Footer',
    mixins: [coreMixin, colorMixin],

    data(){
      return {
        fab: false,
        links: ["Home", "About Us", "Contact Us"],
        selectedItem: 0,
      items: [
        { text: 'home', title: 'Home', link: 'home' },
        { text: 'about-us', title: 'About Us', link: 'about-us' },
        { text: 'features', title: 'Features', link: 'features' },
      ],
       activeLink: '' ,

      };
  },
  computed: {
    ...mapGetters(['getActiveLink']),

  },
  mounted() {
    this.activeLink = this.getActiveLink;

  },
  watch: {
    getActiveLink(link) {
     this.activeLink = link;
    },
  },
  
    methods: {
    ...mapMutations(['setActiveLink']),

      toTop () {
      this.$vuetify.goTo(0)
    },
      onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset ||   e.target.scrollTop || 0
      this.fab = top > 20
    },
    changeRoute(item){
     this.activeLink = item.link;
      localStorage.setItem('activeLink', this.activeLink);
      this.setActiveLink( this.activeLink);
     this.$router.push({ name: item.link }).catch(()=>{});
    }
    }
}
</script>

<style scoped>
.v-list-item {
  min-height: 28px;
}
.v-application p {
    margin-bottom: 0px;
}
.v-list-item__content {
    padding: 6px 0;
}
.theme--dark.v-list {
    background: #382464;
}
.v-list-item-group .v-list-item--active {
    color: #fdce16;
}
.footer-active-link {
  color: #fdce16;
  font-weight: 700;
}
.mobile-view-width {
    width: 100% !important;
    height: 97px !important;
}
</style>

